body {
  font-family: sans-serif;
}
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;

  background: url(img/drill-sm-bg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: white;
  font-family: "Rosario", sans-serif;
}

@media screen and (min-width: 500px) {
  body {
    background-image: url(img/drill-bg.jpg);
    font-size: 1.25em;
  }
}

header {
  width: 100%;
  text-align: center;
  padding: 1em;
}

.logo {
  width: 90vw;
}

main {
  flex-grow: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

footer {
  width: 100%;
  padding: 0.75em;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.2);
}

.services {
  list-style-type: none;
  margin: 0;
  padding: 1em;
  font-size: 1.5em;
  text-transform: uppercase;
  letter-spacing: 0.125em;
  background: rgba(0, 0, 0, 0.5);
  line-height: 1.5em;
}

.svg-icon {
  position: relative;
  top: 0.125em;
  flex-shrink: 0;
  height: 1em;
  width: 1em;
  fill: currentColor;
  transition: fill 0.3s;
}
.quote {
  font-style: italic;
  font-size: 1.5em;
  margin: 0;
}

.who-ya-gonna-call {
  text-align: center;
}

.builder {
  font-size: 2em;
  letter-spacing: 0.25em;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  margin: 0;
}

.rego {
  margin: 0;
  margin-top: 0.5em;
}

@media screen and (min-width: 500px) {
  .services {
    background: none;
  }
  header,
  main {
    background: rgba(0, 0, 0, 0.3);
  }
  footer {
    background: rgba(0, 0, 0, 0.2);
  }
}

.button-container {
  display: flex;
  justify-content: space-evenly;
  max-width: 600px;
  margin: 0 auto;
}

.contact-cta {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  font-family: "Rosario", sans-serif;
  font-weight: bold;
  font-size: 1.5em;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 3px;
  border: 2px solid white;
  border-radius: 3px;
  padding: 0.25em 1em;
}

.visually-hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
}
